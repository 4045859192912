.view-ad-jobs {
    .ad-job {
        margin-bottom: 30px;
        .job-title {
            font-size: 24px;
            padding: 4px 0;
            margin-bottom: 8px;
            border-bottom: 1px solid @brand-red;
        }
        .link-to-content {
            margin: 10px 0;
            font-style: italic;
        }
        .more-info {
            display: flex;
            justify-content: space-between;
            margin: 20px 0;
            font-weight: bold;
            .location {
                position: relative;
                padding-left: 19px;
                &:before {
                    content: "";
                    position: absolute;
                    width: 11px;
                    height: 14px;
                    left: 0px;
                    bottom: 4px;
                    background-image: url('../images/layout/map_marker.png');
                }
            }
            .ad-date {
                position: relative;
                padding-left: 23px;
                &:before {
                    content: "";
                    position: absolute;
                    width: 16px;
                    height: 16px;
                    left: 0px;
                    bottom: 3px;
                    background-image: url('../images/layout/calendar.png');
                }
            }
        }
    }
}

.node-ad-job{
    .contact-box{
        margin-top: 15px;
    }
    .type.subtitle .title{
    word-wrap: break-word;
    }
    .main-container  {    
        .page-header {
            //font-size: 20px;
            display: none;
        }    
    }   
    @media (max-width: 480px){
        .main-content .title
            h1{
                font-size: 20px;
            }
    }    
    .row-address {
        margin-top: 0;
    }
}

.jobs-teaser {
    display: flex;
    align-items: center;
    width: 100%;
    height: 400px;
    margin: 40px 0;
    padding: 15px;
    background-image: url('../images/teaser_images/jobs-teaser-image.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    @media (max-width: @screen-sm-max) {
        height: 500px;
    }
    .jobs-teaser-content {
        @media (min-width: @screen-sm) {
            width: @screen-sm;
        }
        @media (min-width: @screen-md) {
            width: @screen-md;
        }
        @media (min-width: @screen-lg) {
            width: @screen-lg;
        }
        padding: 25px 40px;
        margin: 0 auto;
        color: white;
        background-color: rgba(0, 0, 0, .2);
        h2 {
            margin-top: 10px;
            margin-bottom: 10px;
        }
        h3 {
            margin-top: 5px;
            margin-bottom: 5px;
        }
        a {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }
}

.node-type-ad-marketplace {
    .row-main-content {
        .main-content {
            .title {
                h1 {
                    margin: 0;
                    span {
                        font-weight: 400;
                        text-transform: uppercase;
                        &.offer-advert {
                            color: @brand-red;
                        }
                        &.search-advert {
                            color: @brand-turquoise;
                        }
                    }
                }
            }
            .field_price {
                .field-item {
                    font-size: 24px;
                    font-weight: bold;
                    color: @brand-turquoise;
                }
            }
            .field_description {
                margin-top:10px;
                margin-bottom: 10px;
            }
            .more-information {
                display: flex;
                flex-wrap: wrap;
                margin-top: 40px;
                margin-bottom: 40px;
                & > div {
                    padding: 0;
                    margin-bottom: 1em;
                    .field {
                        padding: 0;
                        @media(min-width: @screen-lg-min) {
                            display: flex;
                            justify-content: space-between;
                            padding-right: 60px;
                        }
                        .field-label {
                            margin: 0;
                        }
                        .field-items {
                            @media(min-width: @screen-lg-min) {
                                text-align: right;
                            }
                        }
                    }
                }
            }
        }
    }
}

.view-marketplace {
    h3 {
        text-align: center;
    }
    .my-adverts-entry,.marketplace-search {
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid @brand-middle-grey;
        &.advert-marketplace {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }
        &.advert-job,&.advert-marketplace {
            .my-advert-link,.advert-link {
                font-size: 24px;
            }
            .advert-image {
                height: 135px;
                background-size: cover;
                background-position: center;
                @media (max-width: @screen-sm-max) {
                    margin-bottom: 10px;
                }
            }
            .advert-text {
                .headline-categories {
                    font-size: 12px;
                    text-transform: uppercase;
                }
                @media (max-width: @screen-sm-max) {
                    padding: 0;
                }
            }
            .advert-price {
                font-size: 20px;
                text-align: left;
                @media (max-width: @screen-sm-max) {
                    padding: 0;
                }
                @media(min-width: @screen-md-min) {
                    text-align: center;
                }
            }
            .advert-created {
                text-align: right;
                @media (max-width: @screen-sm-max) {
                    padding: 0;
                }
                @media(min-width: @screen-md-min) {
                    text-align: center;
                }
            }
            .location {
                position: relative;
                padding-left: 19px;
                font-weight: bold;
                &:before {
                    content: "";
                    position: absolute;
                    width: 11px;
                    height: 14px;
                    left: 0px;
                    bottom: 4px;
                    background-image: url('../images/layout/map_marker.png');
                }
            }
        }
    }
}

#views-exposed-form-marketplace-search-page {
    .views-exposed-form {
        margin: 20px 0;
        .views-exposed-widgets {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;
        }
        @media (max-width: @screen-sm-max) { 
            .views-exposed-widget,
            button {
                width: 100%;
            }
        }
    }
}

.deactivated-ad {
    .container {
        background-color: rgba(0, 0, 0, .05);
        .deactivated-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            font-size: 24px;
            background-color: rgba(0, 0, 0, .8);
            z-index: 99;
            @media(min-width: @screen-md-min) {
                font-size: 60px;
            }
        }
    }
}

.deactivated-ad_page {
    .deactivated-ad_text {
        margin-bottom: 20px;
    }
}

.node-type-ad-marketplace,
.node-type-ad-job {
    .bildergalerie-row .container .field_group_image {
        position: relative;
    }
}
