@teaser-image-height:   150px;

.content-list{

    &.list-type-a {

        .list-entry {
            margin-bottom: 30px;

            .content-title {
                font-size: 24px;
                padding: 4px 0;
                margin-bottom: 8px;
                border-bottom: 1px solid @brand-red;
            }

            .content-info {

                .content-image-wrapper {
                    min-height: @teaser-image-height;
                    
                    .content-image {
                        height: @teaser-image-height;
                        display: block;
                        background-size: cover;
                        background-position: center;
                        background-repeat: no-repeat;
                    }

                    &.no-image {
                        .content-image {
                            background-size: contain;
                            background-position: top;
                        }
                    }
                }

                .content-details {

                    @media(max-width: @screen-sm-max) {
                        padding-top: 5px;
                        padding-bottom: 5px;
                    }

                    .content-description {
                        
                        .fieldlabel {
                            font-weight: 600;
                        }
                    }

                    .link-to-content {
                        margin: 10px 0;
                        font-style: italic;
                    }

                    .more-info {
                        display: flex;
                        justify-content: space-between;
                        margin: 20px 0;
                        font-weight: bold;

                        .location {
                            position: relative;
                            padding-left: 19px;
                            &:before {
                                content: "";
                                position: absolute;
                                width: 11px;
                                height: 14px;
                                left: 0px;
                                top: 4px;
                                background-image: url('../images/layout/map_marker.png');
                            }
                        }

                        .content-created {
                            position: relative;
                            padding-left: 23px;
                            &:before {
                                content: "";
                                position: absolute;
                                width: 16px;
                                height: 16px;
                                left: 0px;
                                top: 3px;
                                background-image: url('../images/layout/calendar.png');
                            }
                        }

                    }
                }
            }
        
        }
    }
}
